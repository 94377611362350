import React from 'react';
import { Link } from 'react-router-dom'; // Make sure this line is included
import styles from './OurStrategy.module.css'; // Ensure the correct path

// Main Commodities Component
const OurStrategy = () => {
  return (
    <div className={styles.OurStrategySection}>
      <HeroSection /> {/* Include the HeroSection */}
      <div className={styles.OurStrategyContentContainer}>
        <h2 className={styles.OurStrategyTitle}>
        </h2>
        <p className={styles.OurStrategyIntro}>
        The three primary business segments of Khadesh Global Integrated Services Limited are Solid Minerals, Agricultural Commodities, and Local Supply. These divisions handle all aspects of the company's supply chain solutions.
        </p>
        <div className={styles.OurStrategyWriteUp}>
          <p>
          By offering complete supply chain solutions on both ends of the market, we skilfully manage relationships by establishing connections between commodity-producing and consuming nations.

          </p>
          <p>
            <strong>Quality and Efficiency</strong>
            <br />
            With the goal of building enduring relationships with clients and being their go-to commodity supply partner, Khadesh Global Integrated Services Limited is unwavering in its commitment to providing the best products and outstanding service.
          </p>
          <p>
          We transport goods via road, air, and sea always choosing the fastest path between points of origin and destinations. You select whether we deliver to your trade partner, warehouse, or retail location.
          </p>
          <p>
            <strong>Your Gateway to Africa</strong>
            <br />
            Khadesh Global Integrated Services Limited provides distinctive market intelligence, knowledge, and experience in Africa as a diversified global commodity trading organisation. We act as your trustworthy entry point into the value chain of commodities in Africa.
          </p>
          <p>
            <strong>Transparency in Trading & Supply</strong>
            <br />
            Openness is essential to how we do business. With the use of cutting-edge technologies, we provide total supply chain transparency while ensuring end-to-end consignment tracking and monitoring around the globe.
          </p>
          <p>
            <strong>Continuity of Commodity Supply Chain</strong>
            <br />
            By tying together worldwide producers, processors, and consumers, we optimise the flow of goods. To improve our customers' experience, we provide value-added services in addition to effective transportation.

          </p>
        </div>
      </div>
      <AdditionalSections /> {/* Ensure this component is defined */}
    </div>
  );
};

// Hero Section Component
const HeroSection = () => (
  <section className="hero-section">
    <div className="hero-image-container">
      <img src="/strategy.ico" alt="Agricultural Commodities" className="hero-image" />
      <div className="hero-overlay">
        <h2>Our Strategy</h2>
      </div>
    </div>
  </section>
);

// Additional Sections Component
const AdditionalSections = () => (
  <section className="additional-sections">
    <WhatWeDo />
    <QuickLinks />
    <CommoditiesAndServices />
    <Support />
    <SocialMedia />
  </section>
);

// What We Do Component
function WhatWeDo() {
  return (
    <section className="what-we-do">
      <div className="content">
        <h2>What We Do</h2>
        <p>
        In order to successfully assist in achieving supply and demand equilibrium, Khadesh Global Integrated Services Limited offers a strategic platform for integrated global sourcing, risk management, and value-added supply solutions for solid mineral resources and agricultural commodities.


        </p>
        
        <p>
           <br />
        <strong>Address:</strong> 21 Ndjamena Crescent Wuse 2 Abuja.
        </p>
      </div>
      <div className="image-container">
        <img src="map.png" alt="Khadesh Global Services" className="what-we-do-image" />
      </div>
    </section>
  );
}



const QuickLinks = () => (
  <div className="quick-links">
    <h3>Quick Links</h3>
    <ul>
      {[
        { name: "Our vision", path: "/our-vision" },
        { name: "Our mission", path: "/our-mission" },
        { name: "Our Partners", path: "/our-partners" },
        { name: "Our commitment", path: "/our-commitment" },
        { name: "Our Value Proposition", path: "/our-value-proposition" },
      ].map(({ name, path }) => (
        <li key={name}>
          <Link to={path} onClick={() => window.scrollTo(0, 0)}>
            {name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const CommoditiesAndServices = () => {
  const services = [
    {
      name: 'Agricultural Commodities',
      link: '/commodities#agriculturalCommoditiesSection', 
    },
    {
      name: 'Solid Mineral Resources',
      link: '/commodities#SolidMineralsSection',
    },
    {
      name: 'Local Supply',
      link: '/local-supply#services-section', 
    },
  ];

  return (
    <div className="commodities-services">
      <h3>Our Commodities & Services</h3>
      <ul>
        {services.map((service, index) => (
          <li key={index}>
            <Link 
              to={service.link} 
              onClick={() => {
                if (service.name === 'Solid Mineral Resources') {
                  setTimeout(() => {
                    const section = document.getElementById("SolidMineralsSection");
                    if (section) {
                      section.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 0);
                } else {
                  window.scrollTo(0, 0);
                }
              }} 
            >
              {service.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};



// Support Component
const Support = () => (
  <div className="support">
    <h3>Support</h3>
    <ul>
  {[
    { name: "Contact Us", path: "/Contact-Us" },
    // Add other links here as needed
  ].map(({ name, path }) => (
    <li key={name}>
      <Link to={path} onClick={() => window.scrollTo(0, 0)}>
        {name}
      </Link>
    </li>
  ))}
</ul>
</div>
);

// Social Media Component
const SocialMedia = () => (
  <div className="social-media">
    <h3>Get Social With Us</h3>
    <div className="social-icons">
      {[
        { platform: "whatsapp", link: "https://wa.me/+2348069081589" },
        { platform: "instagram", link: "https://instagram.com/khadeshglobal" },
        { platform: "facebook", link: "https://www.facebook.com/khadeshglobal" }, // Added Facebook
        { platform: "email", link: "mailto:khadeshglobalintegratedservice@gmail.com" }, // Added Email
      ].map(({ platform, link }) => (
        <a
          key={platform}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={platform}
        >
          <img
            src={`/images/${platform}-logo.png`}
            alt={`${platform} logo`}
            className="social-icon"
          />
        </a>
      ))}
    </div>
  </div>
);



export default OurStrategy;