import React from 'react';
import { Link } from 'react-router-dom'; // Make sure this line is included
import styles from './LocalSupply.module.css'; // Ensure the correct path

// Main Commodities Component
const LocalSupply = () => {
  return (
    <div className={styles.LocalSupplySection}>
      <HeroSection /> {/* Include the HeroSection */}
      <div className={styles.LocalSupplyContentContainer}>
     
        <div className={styles.LocalSupplyWriteUp}>
          <p>
          Local Supply is a pioneering initiative designed to empower local communities by facilitating access to essential goods and services while promoting local businesses. Our mission is to strengthen the local economy by connecting consumers directly with suppliers in their area, ensuring that communities can thrive through sustainable practices and responsible sourcing.          </p>

          <p>
          At Local Supply, we recognize the importance of supporting local economies. By prioritizing local suppliers, we help create jobs, reduce transportation emissions, and foster community resilience. Our platform offers a user-friendly interface that allows consumers to easily browse, discover, and purchase products from local vendors, ensuring that they have access to high-quality, fresh, and unique items that reflect the character of their community.          </p>
<p>
<strong>Quality Control </strong>
</p>
<p>
Product Testing and Certification Before Shipment
Our inhouse Quality Assurance (QA) and Quality Control (QC) (operations) initiatives play key roles in guaranteeing the safety and quality of our products.
</p>

<p>
We work to the specifications of our customers to ensure all products are fully supported with Certificate of Analysis from NAQS or the necessary technical information to comply with destination regulatory requirements.
</p>

<p>
<strong>Our Clients </strong>
</p>
<p>
    <li>
Foreign Buyers & Importers.
</li>
</p>
<p>
    <li>
    Supermarkets & Grocery Chains.
    </li>
</p>
<p>
    <li>
    Local Food Distributors & Wholesalers.
    </li>
</p>
<p>
    <li>
    Local Food Retailers.
    </li>
</p>
<p>
    <li>
    Hotels and Catering Institutions.
    </li>
</p>
<p>
    <li>
    Food Processors.
    </li>
</p>
<p>
    <li>
    Relief & Charitable Organizations.
    </li>
</p>
<p>
    <li>
    Tech Companies.
    </li>
</p>
<p>
    <li>
   Mining and Metal Trading Companies.
    </li>
</p>
<p>
    <li>
    Renewnable Energy Companies.
    </li>
</p>

        </div>
      </div>
      <AdditionalSections /> {/* Ensure this component is defined */}
    </div>
  );
};

// Hero Section Component
const HeroSection = () => (
  <section className="hero-section">
    <div className="hero-image-container">
      <img src="/LocalSupply.ico" alt="Agricultural Commodities" className="hero-image" />
      <div className="hero-overlay">
      <h2>Local Supply</h2>
      </div>
    </div>
  </section>
);

// Additional Sections Component
const AdditionalSections = () => (
  <section className="additional-sections">
    <WhatWeDo />
    <QuickLinks />
    <CommoditiesAndServices />
    <Support />
    <SocialMedia />
  </section>
);

// What We Do Component
function WhatWeDo() {
  return (
    <section className="what-we-do">
      <div className="content">
        <h2>What We Do</h2>
        <p>
        In order to successfully assist in achieving supply and demand equilibrium, Khadesh Global Integrated Services Limited offers a strategic platform for integrated global sourcing, risk management, and value-added supply solutions for solid mineral resources and agricultural commodities.


        </p>
        
        <p>
           <br />
        <strong>Address:</strong> 21 Ndjamena Crescent Wuse 2 Abuja.
        </p>
      </div>
      <div className="image-container">
        <img src="map.png" alt="Khadesh Global Services" className="what-we-do-image" />
      </div>
    </section>
  );
}



const QuickLinks = () => (
  <div className="quick-links">
    <h3>Quick Links</h3>
    <ul>
      {[
        { name: "Our vision", path: "/our-vision" },
        { name: "Our mission", path: "/our-mission" },
        { name: "Our Partners", path: "/our-partners" },
        { name: "Our commitment", path: "/our-commitment" },
        { name: "Our Value Proposition", path: "/our-value-proposition" },
      ].map(({ name, path }) => (
        <li key={name}>
          <Link to={path} onClick={() => window.scrollTo(0, 0)}>
            {name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const CommoditiesAndServices = () => {
  const services = [
    {
      name: 'Agricultural Commodities',
      link: '/commodities#agriculturalCommoditiesSection', 
    },
    {
      name: 'Solid Mineral Resources',
      link: '/commodities#SolidMineralsSection',
    },
    {
      name: 'Local Supply',
      link: '/local-supply#services-section', 
    },
  ];

  return (
    <div className="commodities-services">
      <h3>Our Commodities & Services</h3>
      <ul>
        {services.map((service, index) => (
          <li key={index}>
            <Link 
              to={service.link} 
              onClick={() => {
                if (service.name === 'Solid Mineral Resources') {
                  setTimeout(() => {
                    const section = document.getElementById("SolidMineralsSection");
                    if (section) {
                      section.scrollIntoView({ behavior: "smooth" });
                    }
                  }, 0);
                } else {
                  window.scrollTo(0, 0);
                }
              }} 
            >
              {service.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};



// Support Component
const Support = () => (
  <div className="support">
    <h3>Support</h3>
    <ul>
  {[
    { name: "Contact Us", path: "/Contact-Us" },
    // Add other links here as needed
  ].map(({ name, path }) => (
    <li key={name}>
      <Link to={path} onClick={() => window.scrollTo(0, 0)}>
        {name}
      </Link>
    </li>
  ))}
</ul>
</div>
);

// Social Media Component
const SocialMedia = () => (
  <div className="social-media">
    <h3>Get Social With Us</h3>
    <div className="social-icons">
      {[
        { platform: "whatsapp", link: "https://wa.me/+2348069081589" },
        { platform: "instagram", link: "https://instagram.com/khadeshglobal" },
        { platform: "facebook", link: "https://www.facebook.com/khadeshglobal" }, // Added Facebook
        { platform: "email", link: "mailto:khadeshglobalintegratedservice@gmail.com" }, // Added Email
      ].map(({ platform, link }) => (
        <a
          key={platform}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={platform}
        >
          <img
            src={`/images/${platform}-logo.png`}
            alt={`${platform} logo`}
            className="social-icon"
          />
        </a>
      ))}
    </div>
  </div>
);



export default LocalSupply;